.atm_mk_h2mmj6{position:relative;}
.atm_9s_1txwivl{display:flex;}
.atm_ar_1bp4okc.atm_ar_1bp4okc{flex-direction:column;}
.atm_fc_1yb4nlp.atm_fc_1yb4nlp{justify-content:space-between;}
.atm_vy_1osqo2v{width:100%;}
.atm_cx_exct8b{gap:16px;}
.atm_l8_exct8b{padding:16px;}
@media screen and (min-width: 1024px){.atm_1itq9jb_eque28.atm_1itq9jb_eque28{padding:16px 128px;}}
.atm_h_1h6ojuz.atm_h_1h6ojuz{align-items:center;}
.atm_cx_1vi7ecw{gap:32px;}
.atm_l8_1tjxcj6{padding:16px 0;}
.atm_40_t8n96y{border-bottom:1px solid #f2f2f2;}
@media screen and (min-width: 1024px){.atm_1gs72ja_vrvcex.atm_1gs72ja_vrvcex.atm_1gs72ja_vrvcex{flex-direction:row;}}
.atm_cx_viql5b{gap:2rem;}
@media screen and (min-width: 1024px){.atm_w5wfdd_1wtoqjy.atm_w5wfdd_1wtoqjy{gap:3rem;}}
.atm_ar_vrvcex.atm_ar_vrvcex{flex-direction:row;}
.atm_cx_1k8s52q{gap:1.5rem;}
.f1p65nlz{font-size:1rem;color:#33333D;}.f1p65nlz:hover{color:#8BC3FE;}
.f1hesbam{font-size:0.75rem;text-align:center;}@media screen and (min-width: 1024px){.f1hesbam{font-size:0.85rem;}}
.atm_e2_viql5b{height:2rem;}
.atm_vy_viql5b{width:2rem;}
.atm_5j_1ssbidh{border-radius:50%;}
.atm_9j_tlke0l{cursor:pointer;}
.atm_9s_116y0ak{display:inline-flex;}
.atm_fc_1h6ojuz.atm_fc_1h6ojuz{justify-content:center;}
.atm_c8_1wbju5m.atm_c8_1wbju5m{font-size:1.15rem;}
.atm_18uqgji_1i7blby:hover{color:#8BC3FE;}
.atm_1ikp6z7_1i7blby:hover path{fill:#8BC3FE;}
.atm_e2_f6fqlb{height:18px;}
.atm_vy_f6fqlb{width:18px;}
