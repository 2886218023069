.snackbar {
  position: fixed;
  left: 50%;
  top: 90%;
  transform: translate(-50%, -50%);
  width: 350px;
  height: 60px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  text-align: center;
  z-index: 1000;
}

.snackbar .symbol {
  flex: 20%;
}

.snackbar .message {
  flex: 80%;
  text-align: start;
  font-weight: bold;
}

#show {
  visibility: visible;
}
#hide {
  visibility: hidden;
}
