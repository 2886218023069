:root {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

html {
  font-family: "General Sans", sans-serif;
  font-size: 100%;
  block-size: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  text-rendering: geometricPrecision;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  overscroll-behavior: none;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  line-height: 1.5rem;
}

h1 {
  font-size: 1.5rem;
  font-weight: 900;
}

h2 {
  font-size: 1.25rem;
  font-weight: 900;
}

.registration-error-root {
  display: flex;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  padding: 2.25rem 0;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  overflow: auto;

  background: linear-gradient(180deg, #9eccfd 0%, #ecf3fb 100%) no-repeat fixed;
}

.registration-error-main,
.registration-error-header {
  height: 100%;
  width: 100%;
  max-width: 55rem;
  margin: 0 auto;
}

.registration-error-main {
  box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.35);
  background: #fafafa;
}

.registration-error-header {
  display: flex;
  max-height: 17rem;
  padding: 0.625rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;

  background: #fff;
}

.registration-error-header img {
  width: 100%;
  max-height: 17rem;
}

.registration-error-content {
  display: flex;
  padding: 1.5rem;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  align-self: stretch;
}

.registration-error-footer {
  padding: 1.5rem;

  color: #909090;
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.00569rem;
}

.registration-error-footer a {
  color: #909090;
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: underline;
}

.primary-button {
  display: flex;
  max-height: 2.25rem;
  padding: 0.625rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;

  color: #424242;
  font-family: inherit;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.07rem;
  text-transform: uppercase;

  border: 0;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.primary-button {
  background: #8bc3fe;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
}

.primary-button:hover {
  background: #85b8ef;
}

.primary-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.primary-button:disabled:hover {
  background: #8fc5fe;
  opacity: 0.7;
}

/* Pages */

.article {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  align-self: stretch;
}

.h1 {
  color: #616161;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.00975rem;
}

.p,
.b,
.i {
  color: #616161;
}

.p {
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.4rem */
  letter-spacing: 0.0085rem;
}

.em {
  font-weight: 700;
}

/*Small devices (landscape phones, 768px and up)*/
@media (max-width: 768px) {
  .registration-error-root {
    padding: 0;
  }

  .registration-error-main {
    min-height: 100vh;
  }

  .registration-error-content {
    padding: 1rem;
    gap: 1rem;
  }

  .button {
    width: 100%;
  }
}
