.huqclga{font-weight:700;font-size:20px;}
.cux8qgg{width:100%;padding:4vh 4vw;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:32px;}@media screen and (min-width: 800px){.cux8qgg{padding:4vh 16vw;}}
.atm_9s_1txwivl{display:flex;}
.atm_ar_1bp4okc.atm_ar_1bp4okc{flex-direction:column;}
.atm_cx_ftgil2{gap:8px;}
.atm_l8_1tjxcj6{padding:16px 0;}
.atm_j6_dtre0s{min-height:50vh;}
.atm_l8_1tcgj5g{padding:24px;}
.atm_9j_tlke0l{cursor:pointer;}
.atm_cx_exct8b{gap:16px;}
.atm_5j_rdoju8{border-radius:9px;}
.atm_vb_glywfm{user-select:none;}
.atm_1y17hwk_1a77vov.atm_1y17hwk_1a77vov:hover{background-color:#f2f2f2;}
.t1o0xoxj{height:36px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;-webkit-justify-content:center;justify-content:center;gap:16px;width:100%;}
.atm_bx_1bhpaz9.atm_bx_1bhpaz9{font-family:Lato,sans-serif;}
.atm_l8_9o6drx{padding:8px 16px;}
.atm_5j_12xxubj{border-radius:100px;}
.atm_3f_idpfg4{border:0;}
.atm_kd_idpfg4{outline:0;}
.atm_c8_1wbju5m.atm_c8_1wbju5m{font-size:1.15rem;}
.atm_cs_8n312b.atm_cs_8n312b{font-weight:bold;}
.atm_vy_1ns9ob4{width:fit-content;}
.atm_2d_1j28jx2.atm_2d_1j28jx2{background-color:transparent;}
.atm_7l_vihzp9{color:var(--1vaw9fa);}
.atm_18uqgji_15u7rdg:hover{color:#6289b3;}
.q1u3yljl{cursor:default;}
