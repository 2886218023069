.atm_9s_1txwivl{display:flex;}
.atm_h_1h6ojuz.atm_h_1h6ojuz{align-items:center;}
.atm_l8_1vi7ecw{padding:32px;}
.atm_vy_1osqo2v{width:100%;}
.atm_fc_1h6ojuz.atm_fc_1h6ojuz{justify-content:center;}
.atm_uc_g4qm9s{transition:padding,200ms;}
@media screen and (min-width: 1024px){.atm_1itq9jb_h92qno.atm_1itq9jb_h92qno{padding:0 400px 128px 400px;}}
.atm_bx_1bhpaz9.atm_bx_1bhpaz9{font-family:Lato,sans-serif;}
.atm_5j_i2wt44{border-radius:6px;}
.atm_3f_6k2nhi{border:1px solid #dcdcdc;}
.atm_l8_11nx8fq{padding:12px 16px;}
.atm_my_glywfm{resize:none;}
.atm_c8_1j6vyhq.atm_c8_1j6vyhq{font-size:1rem;}
.atm_7l_15uk3js{color:#FFFFFF;}
.atm_2d_1i7blby.atm_2d_1i7blby{background-color:#8BC3FE;}
.atm_l8_1g3z47g{padding:20px 32px;}
.atm_9j_tlke0l{cursor:pointer;}
.atm_3f_tjutg8{border:0.1rem solid;}
.atm_5j_1fwxnve{border-radius:12px;}
.atm_c8_1k92wuq.atm_c8_1k92wuq{font-size:1.25rem;}
.atm_cs_1fw03zg.atm_cs_1fw03zg{font-weight:700;}
.atm_t9_angws6{text-transform:uppercase;}
.atm_fr_hgvujj{letter-spacing:0.25px;}
.atm_uc_1q0le16{transition:background-color 200ms;}
.atm_1y17hwk_15u7rdg.atm_1y17hwk_15u7rdg:hover{background-color:#6289b3;}
.fec3eo8{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:16px;width:100%;}
