.atm_9s_1txwivl{display:flex;}
.atm_ar_187zu2b.atm_ar_187zu2b{flex-direction:var(--alxg45);}
.atm_h_1h6ojuz.atm_h_1h6ojuz{align-items:center;}
.atm_l8_1e5tdno{padding:64px 32px;}
.atm_vy_1osqo2v{width:100%;}
.atm_e2_1osqo2v{height:100%;}
.atm_fc_1yb4nlp.atm_fc_1yb4nlp{justify-content:space-between;}
.atm_uc_g4qm9s{transition:padding,200ms;}
.atm_cx_1vi7ecw{gap:32px;}
@media screen and (min-width: 870px){.atm_1d039hc_vrvcex.atm_1d039hc_vrvcex.atm_1d039hc_vrvcex{flex-direction:row;}}
@media screen and (min-width: 870px){.atm_1tsj4ti_1fwpi09.atm_1tsj4ti_1fwpi09{gap:64px;}}
@media screen and (min-width: 1024px){.atm_1itq9jb_xlgzpo.atm_1itq9jb_xlgzpo{padding:128px;}}
@media screen and (min-width: 1024px){.atm_1s5w3ww_1osqo2v.atm_1s5w3ww_1osqo2v{height:100%;}}
