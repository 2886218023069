.hero {
  display: grid;
  grid-template-columns: 2fr 8fr 2fr;
  grid-template-rows: 25% 35% 25%;
  grid-column-gap: 2rem;
  height: 100vh;
  margin: 0 auto;
  padding: 2rem 4.5rem;

  & section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    grid-row: 2;
    grid-column: 2;
  }

  & h1 {
    width: 100%;
    font-size: 2.75rem;
    color: #6289b3;
  }

  & p {
    width: 100%;
    font-size: 1.25rem;
    line-height: 1.5;
    color: #181818;
  }

  img {
    width: 100%;
    max-width: 400px;
    border-radius: 50%;
  }

  img:first-child {
    grid-row: 1;
    grid-column: 1;
  }

  & img:last-child {
    grid-row: 3;
    grid-column: 3;
  }
}

.heroAccent {
  font-weight: 700;
  color: #6289b3;
}

@media (max-width: 768px) {
  .hero {
    grid-template-columns: 1fr;
    grid-column-gap: 0;

    & h1,
    & p {
      text-align: center;
    }

    & img {
      display: none;
    }
  }
}
