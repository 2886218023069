.atm_e2_1rxhsqz{height:70vh;}
.atm_9s_1txwivl{display:flex;}
.atm_h_1h6ojuz.atm_h_1h6ojuz{align-items:center;}
.atm_fc_itczq0.atm_fc_itczq0{justify-content:space-evenly;}
.atm_mk_h2mmj6{position:relative;}
.atm_cx_1fwpi09{gap:64px;}
.atm_uc_10c5ah1{transition:all 0.1s ease;}
.atm_l8_1tcgj5g{padding:24px;}
@media screen and (min-width: 640px){.atm_1kqiunp_fc5lwq.atm_1kqiunp_fc5lwq{height:calc(100vh - 80px);}}
@media screen and (min-width: 640px){.atm_2dpu4h_1fwpi09.atm_2dpu4h_1fwpi09{padding:64px;}}
@media screen and (min-width: 870px){.atm_i69qda_rw9lz9.atm_i69qda_rw9lz9{padding:96px;}}
@media screen and (min-width: 1024px){.atm_1itq9jb_1umnl77.atm_1itq9jb_1umnl77{padding:64px 128px;}}
.atm_cs_1fw03zg.atm_cs_1fw03zg{font-weight:700;}
.atm_c8_lszoh0.atm_c8_lszoh0{font-size:clamp(3rem, 5vw + 1rem, 5rem);}
.atm_gq_1k8s52q.atm_gq_1k8s52q{margin-bottom:1.5rem;}
.atm_r3_1h6ojuz{text-align:center;}
.atm_uc_vbgw6z{transition:font-size 200ms;}
.atm_7l_15u7rdg{color:#6289b3;}
.h1er2bf{border-radius:50%;width:0;height:auto;position:absolute;top:24px;}@media screen and (min-width: 640px){.h1er2bf{left:64px;width:clamp(15%, 18%, 22%);}}@media screen and (min-width: 870px){.h1er2bf{left:96px;}}
.hqu1rdi{border-radius:50%;width:0;height:auto;position:absolute;bottom:24px;}@media screen and (min-width: 640px){.hqu1rdi{right:64px;width:clamp(15%, 18%, 22%);}}@media screen and (min-width: 870px){.hqu1rdi{right:96px;}}
